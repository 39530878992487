import AboutUs from "./Components/AboutUs";
import CourseDetails from "./Components/CourseDetails";
import ContactUs from "./Components/ContactUs";
import OciServices from "./Components/OciServices";
import Gallery from "./Components/Gallery";
import Courses from "./Components/Courses";
import Blog from "./Components/Blog";
import Recruitment from "./Components/Recruitment";
import Country from "./Components/Country";
import CountryList from "./Components/CountryList";
import BookAppointmet from "./Components/BookAppointmet";
import BlogDetail from "./Components/BlogDetail";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Terms from "./Components/Terms";
import GlobalEducation from "./Components/GlobalEducation";
import ImmigrationAssistance from "./Components/ImmigrationAssistance";
import NoPage from "./Components/NoPage";

export const routes = [
  { path: "study-abroad-courses", element: <Courses pageTitle="Study Abroad Courses" /> },
  { path: "study-abroad-countries", element: <CountryList pageTitle="Study Abroad Countries" /> },
  { path: "privacy-policy", element: <PrivacyPolicy pageTitle="Privacy Policy" /> },
  { path: "terms-and-conditions", element: <Terms pageTitle="Terms and conditions of use" /> },
  { path: "about-us", element: <AboutUs pageTitle="About Us" /> },
  {
    path: "study-abroad-courses/mba-in-abroad",
    element: <CourseDetails pageTitle="MBA in Abroad" courseId={1} />,
  },
  {
    path: "study-abroad-courses/banking-and-finance-courses-abroad",
    element: <CourseDetails pageTitle="Banking and Finance Courses in Abroad" courseId={2} />,
  },
  {
    path: "study-abroad-courses/engineering-courses-abroad",
    element: <CourseDetails pageTitle="Engineering Courses in Abroad" courseId={3} />,
  },
  {
    path: "study-abroad-courses/computer-science-courses-abroad",
    element: <CourseDetails pageTitle="Computer Science Courses Abroad" courseId={4} />,
  },
  {
    path: "study-abroad-courses/mass-communication",
    element: <CourseDetails pageTitle="Mass Communication" courseId={5} />,
  },
  {
    path: "study-abroad-courses/hospitality-and-tourism-management",
    element: <CourseDetails pageTitle="Hospitality and Tourism Courses Abroad​" courseId={6} />,
  },
  {
    path: "study-abroad-courses/event-management-courses-abroad",
    element: <CourseDetails pageTitle="Event Management Courses Abroad" courseId={7} />,
  },
  {
    path: "study-abroad-courses/data-science-courses-abroad",
    element: <CourseDetails pageTitle="Data Science Courses Abroad" courseId={8} />,
  },
  {
    path: "study-abroad-courses/health-science-courses-abroad",
    element: <CourseDetails pageTitle="Health Science Courses Abroad" courseId={9} />,
  },
  {
    path: "study-abroad-courses/mba-in-ireland",
    element: <CourseDetails pageTitle="MBA in Ireland" courseId={10} />,
  },
  {
    path: "study-abroad-courses/artificial-intelligence-courses-to-study-abroad",
    element: <CourseDetails pageTitle="Artificial Intelligence Courses Abroad" courseId={11} />,
  },
  {
    path: "study-abroad-courses/study-social-work-abroad",
    element: <CourseDetails pageTitle="Social Work Courses Abroad" courseId={12} />,
  },
  {
    path: "study-in-canada",
    element: <Country pageTitle="Study in Canada" countryId={6} />,
  },
  { path: "study-in-uk-agency", element: <Country pageTitle="Study in UK" countryId={1} /> },
  { path: "study-in-france", element: <Country pageTitle="Study in France" countryId={3} /> },
  { path: "study-in-germany", element: <Country pageTitle="Study in Germany" countryId={4} /> },
  { path: "study-in-dubai", element: <Country pageTitle="Study in Dubai" countryId={5} /> },
  {
    path: "study-abroad-in-ireland",
    element: <Country pageTitle="Study in Ireland" countryId={2} />,
  },
  {
    path: "malaysia-study-abroad-agency",
    element: <Country pageTitle="Study in Malaysia" countryId={7} />,
  },
  { path: "study-in-usa", element: <Country pageTitle="Study in USA" countryId={8} /> },
  {
    path: "study-abroad-in-australia",
    element: <Country pageTitle="Study in Australia" countryId={9} />,
  },
  {
    path: "study-in-singapore",
    element: <Country pageTitle="Study in Singapore" countryId={10} />,
  },
  {
    path: "study-in-new-zealand",
    element: <Country pageTitle="Study in New Zealand" countryId={11} />,
  },
  {
    path: "study-in-sweden",
    element: <Country pageTitle="Study in Sweden" countryId={12} />,
  },
  { path: "study-in-finland", element: <Country pageTitle="Study in Finland" countryId={13} /> },
  { path: "study-in-denmark", element: <Country pageTitle="Study in Denmark" countryId={14} /> },
  { path: "study-in-malta", element: <Country pageTitle="Study in Malta" countryId={15} /> },
  { path: "study-in-estonia", element: <Country pageTitle="Study in Estonia" countryId={16} /> },
  {
    path: "study-in-lithuania",
    element: <Country pageTitle="Study in Lithuania" countryId={17} />,
  },
  {
    path: "study-in-netherlands",
    element: <Country pageTitle="Study in Netherlands" countryId={18} />,
  },
  {
    path: "study-in-poland",
    element: <Country pageTitle="Study in Poland" countryId={19} />,
  },
  {
    path: "study-in-norway",
    element: <Country pageTitle="Study in Norway" countryId={20} />,
  },
  { path: "contact-us", element: <ContactUs pageTitle="Contact Us" /> },
  { path: "gallery", element: <Gallery pageTitle="Gallery" /> },
  { path: "blog", element: <Blog pageTitle="Blog" /> },
  {
    path: "services/oci-application-assistance",
    element: <OciServices pageTitle="OVERSEAS CITIZENSHIP OF INDIA (OCI) CARDHOLDER" />,
  },
  { path: "blog-detail", element: <BlogDetail pageTitle="Blog" /> },
  { path: "recruitment", element: <Recruitment pageTitle="Recruitment" /> },
  { path: "book-an-appointment", element: <BookAppointmet pageTitle="Book An Appointmet" /> },
  { path: "global-education", element: <GlobalEducation pageTitle="Global Education" /> },
  {
    path: "immigration-assistance",
    element: <ImmigrationAssistance pageTitle="Immigration Assistance" />,
  },
  { path: "*", element: <NoPage pageTitle="Page Not Found" /> },
];
